// import React from 'react'
import modernToDoImg from "../../assets/modern-to-do.webp";
import cryptoTrackerImg from "../../assets/crypto-tracker.webp";
import webToolsImg from "../../assets/web-tools.webp";
import digitalMarketingAgencyImg from "../../assets/digital-marketing-agency.webp";
import notesAppImg from "../../assets/notes-app.webp";
import chromeExtensionImg from "../../assets/chrome-extension.webp";
import romanianFestivalsImg from "../../assets/romanian-festivals.webp";
import encryptionAppImg from "../../assets/encryption-app.webp";
import resumeGeneratorImg from "../../assets/resume-generator.webp";
import tattooStudio from "../../assets/tattoo-studio.webp";
import AInterviu from "../../assets/ai-interviu.webp";

export const projectsInfo = [
  {
    src: AInterviu,
    alt: "Learn With AI",
    title: "Learn With AI",
    codeLink: true,
    live: "https://ainterviu.netlify.app/",
    code: "https://github.com/cosmin-panescu/Learn-with-AI",
  },
  {
    src: resumeGeneratorImg,
    alt: "Resume Generator for Programmers",
    title: "Resume Generator",
    codeLink: true,
    live: "https://cosmin-panescu.github.io/Resume-Generator/",
    code: "https://github.com/cosmin-panescu/Resume-Generator",
  },
  {
    src: tattooStudio,
    alt: "Tattoo Studio Landing Page",
    title: "Tattoo Studio",
    codeLink: true,
    live: "https://cosmin-panescu.github.io/Tattoo-Studio-LandingPage/",
    code: "https://github.com/cosmin-panescu/Tattoo-Studio-LandingPage",
  },
  {
    src: cryptoTrackerImg,
    alt: "Crypto Tracker Project",
    title: "Crypto Tracker",
    codeLink: true,
    live: "https://cosmin-panescu.github.io/Crypto-Tracker/",
    code: "https://github.com/cosmin-panescu/Crypto-Tracker",
  },
  {
    src: chromeExtensionImg,
    alt: "Chrome New Tab Extension",
    title: "Chrome Extension",
    codeLink: true,
    live: "https://cosmin-panescu.github.io/Chrome-Extension-NewTab/",
    code: "https://github.com/cosmin-panescu/Chrome-Extension-NewTab",
  },
  {
    src: notesAppImg,
    alt: "Notes App",
    title: "Notes App",
    codeLink: true,
    live: "https://cosmin-panescu.github.io/Notes-App/",
    code: "https://github.com/cosmin-panescu/notes-app",
  },
];

export const moreProjectsInfo = [
  {
    src: webToolsImg,
    alt: "Web Tolls Project",
    title: "Web Tools",
    codeLink: true,
    live: "https://cosmin-panescu.github.io/Web-Tools/",
    code: "https://github.com/cosmin-panescu/Web-Tools",
  },
  {
    src: digitalMarketingAgencyImg,
    alt: "Digital Marketing Agency Site Project",
    title: "Digital Marketing Agency",
    codeLink: true,
    live: "https://amaisse.com/",
    code: "https://github.com/cosmin-panescu/Marketing-Agency-Website",
  },
  {
    src: romanianFestivalsImg,
    alt: "Romanian Festivals Website",
    title: "Romanian Festivals",
    codeLink: true,
    live: "https://romania-events-website.vercel.app/",
    code: "https://github.com/cosmin-panescu/Romania-Events-Website",
  },
  {
    src: encryptionAppImg,
    alt: "Encryption-Decryption App",
    title: "Encryption & Decryption",
    codeLink: true,
    live: "https://cosmin-panescu.github.io/Encryption-Decryption/",
    code: "https://github.com/cosmin-panescu/Encryption-Decryption",
  },
  {
    src: modernToDoImg,
    alt: "Modern To Do App Project",
    title: "Modern To Do",
    codeLink: true,
    live: "https://cosmin-panescu.github.io/Modern-To-Do-List/",
    code: "https://github.com/cosmin-panescu/Modern-To-Do-List",
  },
];
